import { getWhiteLabelCssVariables } from '@zenchef/ds-react'
import { darken, lighten } from 'polished'
import { CSSProperties } from 'react'

const getRestaurantColorCssVariables = (color?: string) => {
  if (color === undefined) {
    return {}
  }
  const whiteLabelVariables = getWhiteLabelCssVariables(color)
  return {
    ...whiteLabelVariables,
    '--colors-restaurant-darker': darken(0.07, color),
    '--colors-restaurant-lighter': lighten(0.07, color)
  } as CSSProperties
}

export { getRestaurantColorCssVariables }
