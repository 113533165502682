import { Flex, styled } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import { HTMLStyledProps } from '@zenchef/styled-system/types'
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'

import Scrollable from '@/components/Scrollable'

interface CollapsibleProps extends PropsWithChildren {
  isCollapsed: boolean
  toggle: () => void
}

interface CollapsibleContextState {
  isCollapsed: boolean
  toggle: () => void
}

const CollapsibleContext = createContext<CollapsibleContextState>({} as CollapsibleContextState)

const Collapsible = ({ isCollapsed, toggle, children }: CollapsibleProps) => {
  return <CollapsibleContext.Provider value={{ isCollapsed, toggle }}>{children}</CollapsibleContext.Provider>
}

type CollapsibleHeaderProps = HTMLStyledProps<'button'>

const CollapsibleHeader = ({ children, ...props }: CollapsibleHeaderProps) => {
  const { toggle } = useContext(CollapsibleContext)
  return (
    <styled.button cursor={props.disabled ? 'not-allowed' : 'pointer'} {...props} onClick={toggle}>
      {children}
    </styled.button>
  )
}

type CollapsibleContentProps = PropsWithChildren & {
  scrollable?: boolean
  fixedHeight?: boolean
} & HTMLStyledProps<'div'>

const getDynamicHeightStyle = (isCollapsed, scrollable, contentRefScrollheight, fixedHeight) => {
  let height = 'auto'

  if (isCollapsed) {
    height = '0px'
  } else if (scrollable || fixedHeight) {
    height = token.var('sizes.scrollable-accordion-height')
  } else if (contentRefScrollheight) {
    height = `${contentRefScrollheight}px`
  }
  return height
}

const CollapsibleContent = ({ children, scrollable, fixedHeight, maxHeight, ...props }: CollapsibleContentProps) => {
  const { isCollapsed } = useContext(CollapsibleContext)
  const [height, setHeight] = useState<string>(isCollapsed ? '0px' : 'auto')
  const contentRef = useRef<HTMLDivElement>(null)

  const dynamicMaxHeight = maxHeight ? undefined : height

  useEffect(() => {
    const dynamicHeight = getDynamicHeightStyle(isCollapsed, scrollable, contentRef.current?.scrollHeight, fixedHeight)
    if (dynamicHeight !== height) {
      setHeight(dynamicHeight)
    }
  }, [fixedHeight, height, isCollapsed, scrollable])

  return (
    <Flex
      style={{ height, maxHeight: dynamicMaxHeight }}
      opacity={isCollapsed ? '0' : '1'}
      visibility={isCollapsed ? 'hidden' : 'visible'}
      transition='all 0.4s cubic-bezier(0.33, 1, 0.68, 1), opacity 0.1s ease-in'
      width='100%'
      flexDirection='column'
      _scrollbar={{ display: 'none' }}
      overflowY='auto'
      maxHeight={maxHeight}>
      <Scrollable fancyScrollbar={scrollable}>
        <styled.div {...props} ref={contentRef} height='100%'>
          {children}
        </styled.div>
      </Scrollable>
    </Flex>
  )
}

Collapsible.Header = CollapsibleHeader
Collapsible.Content = CollapsibleContent

export default Collapsible
