import { RichText, Text } from '@zenchef/ds-react'
import { Stack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/redesign/common/Button'
import Hr from '@/components/redesign/common/Hr'
import { Bookings } from '@/types/types'
import { useGoTo, useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'

import useSlotSelector from './useSlotSelector'

const ShiftHeader = observer(({ shift }: { shift: Bookings.Shift }) => {
  const { t, translateField } = useTranslation()
  const { goTo } = useGoTo()
  const { selectedRoomName, getAllAvailableSlots, nbPaxSelected, shouldDisplaySuggestedRestaurantsForShift } =
    useSlotSelector()
  const full = getAllAvailableSlots(shift).length === 0
  const shiftComment = translateField(shift.comment)
  const shouldDisplaySuggestedRestaurants = shouldDisplaySuggestedRestaurantsForShift(shift)

  return (
    <Stack gap='gap.2' pb='padding.1'>
      <div>
        <Text textStyle='title.s' color='content.neutral.bold'>
          {translateField(shift.name_translations) ?? shift.name}
        </Text>

        {shiftComment ? <RichText color='content.neutral.subtle' {...safelySetInnerHTML(shiftComment)} /> : null}
      </div>
      {full ? (
        <>
          <Hr borderColor='border.neutral-on-brand.subtle' textStyle='mono.s' color='content.neutral.subtle'>
            {selectedRoomName ? t('no_matches_found') : t('for_x_pax', { pax: nbPaxSelected, count: nbPaxSelected })}
          </Hr>
          {shouldDisplaySuggestedRestaurants ? (
            <Button
              hierarchy='outlined'
              reversed
              size='normal'
              onClick={() => goTo('suggestions')}
              testId='suggestions'>
              {t('suggested_restaurants_day_button')}
            </Button>
          ) : null}
        </>
      ) : null}
    </Stack>
  )
})

ShiftHeader.displayName = 'ShiftHeader'

export default ShiftHeader
