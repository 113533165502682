import { sva } from '@zenchef/styled-system/css'

export const suggestedRestaurantCard = sva({
  slots: ['header', 'img', 'body'],
  base: {
    header: {
      display: 'flex',
      width: '100%',
      pr: 'padding.2',
      pl: 'padding.4',
      gap: 'gap.2',
      alignItems: 'center',
      borderRadius: 'l',
      border: 'm',
      color: 'content.neutral.subtle',
      borderColor: {
        base: 'border.neutral-on-brand.bold',
        _hover: 'border.neutral-on-brand.bold-hover',
        _focusVisible: 'border.neutral-on-brand.bold-hover'
      },
      bgColor: {
        _hover: 'background.neutral-on-brand.base.subtlest-hover',
        _focusVisible: 'background.brand.bold'
      },
      _focusVisible: {
        shadow: 'effect.focus-ring.neutral',
        outline: 'none'
      }
    },
    body: {
      p: 'padding.2'
    },
    img: {
      width: '80px',
      height: 'auto',
      bgColor: 'restaurant',
      borderLeftRadius: 'l',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      alignSelf: 'stretch',
      display: 'flex'
    }
  },
  variants: {
    img: {
      true: {
        header: {
          pl: 'gap.0'
        }
      }
    }
  }
})
