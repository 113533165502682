import { Icon, Text } from '@zenchef/ds-react'
import { Box, HStack, Stack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react-lite'
import { useContext, useMemo, useState } from 'react'

import { buttonStyle } from '@/components/redesign/common/Button'
import Collapsible from '@/components/redesign/common/Collapsible/Collapsible'
import { SlotStatus } from '@/components/redesign/filters/SlotInput/SlotButton'
import SlotInput from '@/components/redesign/filters/SlotInput/SlotInput'
import useSlotSelector from '@/components/redesign/filters/SlotInput/useSlotSelector'
import { suggestedRestaurantCard } from '@/components/redesign/suggestions/suggestedRestaurantRecipes'
import Loader from '@/components/ui/Loader'
import { Bookings } from '@/types/types'
import getFormattedAddress from '@/utils/getFormattedAddress'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

interface SuggestedRestaurantCardProps {
  onSlotSelected: (slot: Bookings.Slot, shift: Bookings.Shift, waitingList: boolean) => void
}

const SuggestedRestaurantCard = observer(({ onSlotSelected }: SuggestedRestaurantCardProps) => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  const [isCollapsed, collapse] = useState(true)
  const { name, city, address, address2, zip, restaurantId, getAllAvailableSlots } = appStore.state
  const { shifts } = useSlotSelector()
  const numberOfAvailableSlots = useMemo(
    () => shifts.reduce((sum, shift) => sum + getAllAvailableSlots(shift).length, 0),
    [getAllAvailableSlots, shifts]
  )

  if (restaurantId === undefined) {
    return <Loader />
  }

  const img = appStore.state.logo ? `url(${appStore.state.logo})` : undefined
  const classes = suggestedRestaurantCard({ img: img !== undefined })

  return (
    <Box>
      <Collapsible isCollapsed={isCollapsed} toggle={() => collapse((prev) => !prev)}>
        <Collapsible.Header className={classes.header}>
          {img !== undefined && <Box className={classes.img} style={{ backgroundImage: img }} />}
          <Stack textAlign='left' py='padding.2' flexGrow={1} gap='gap.2'>
            <Stack gap='gap.0'>
              <Text color='content.neutral.bold' textStyle='title.m'>
                {name}
              </Text>
              <Text textStyle='paragraph.s.regular'>
                {getFormattedAddress({
                  address,
                  address2,
                  zip,
                  city
                })}
              </Text>
            </Stack>
            <HStack textWrap='nowrap'>
              <SlotStatus status='available' />
              <Text textStyle='paragraph.m.regular'>
                {t('suggested_restaurant.count_availabilities', { count: numberOfAvailableSlots })}
              </Text>
            </HStack>
          </Stack>
          <div className={buttonStyle({ hierarchy: 'ghost', reversed: true, iconOnly: true })}>
            <Icon name={isCollapsed ? 'chevron-right' : 'chevron-down'} />
          </div>
        </Collapsible.Header>
        <Collapsible.Content scrollable className={classes.body}>
          <SlotInput onSlotSelected={onSlotSelected} />
        </Collapsible.Content>
      </Collapsible>
    </Box>
  )
})

SuggestedRestaurantCard.displayName = 'SuggestedRestaurantCard'

export default SuggestedRestaurantCard
