import { Box, Stack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import useSearchBookingWish from '@/components/redesign/filters/useSearchBookingWish'
import SuggestedRestaurantCard from '@/components/redesign/suggestions/SuggestedRestaurantCard'
import Loader from '@/components/ui/Loader'
import { getRestaurantColorCssVariables } from '@/utils/restaurantColor'
import StoresContext from '@/utils/StoresContext'

const SuggestedRestaurantsSection = observer(() => {
  const { appStore } = useContext(StoresContext)

  return (
    <Stack gap='gap.1'>
      {appStore.state.suggestedRestaurantIds
        .filter((restaurantId) => appStore.state.suggestedAppStores[restaurantId]?.state.initialized)
        .map((restaurantId, index) => {
          const suggestedAppStore = appStore.state.suggestedAppStores[restaurantId]
          const { initialized, isCurrentDayAvailableForCurrentPax } = suggestedAppStore.state

          if (!initialized) {
            return <Loader />
          }
          return isCurrentDayAvailableForCurrentPax ? (
            <StoresContext.Provider value={{ appStore: suggestedAppStore }} key={`${restaurantId}-${index}`}>
              <SuggestedRestaurantContainer restaurantId={restaurantId} />
            </StoresContext.Provider>
          ) : null
        })}
    </Stack>
  )
})

interface SuggestedRestaurantContainerProps {
  restaurantId: number
}

const SuggestedRestaurantContainer = observer(({ restaurantId }: SuggestedRestaurantContainerProps) => {
  const { appStore } = useContext(StoresContext)
  const { goToNextPageInSuggestedRestaurant } = useSearchBookingWish()
  const { theme, initialized } = appStore.state
  if (!initialized) {
    return null
  }
  return (
    <Box style={getRestaurantColorCssVariables(theme.colors.primary)}>
      <SuggestedRestaurantCard
        onSlotSelected={(slot, shift, waitingList) => {
          goToNextPageInSuggestedRestaurant(slot, shift, waitingList, restaurantId)
        }}
      />
    </Box>
  )
})

SuggestedRestaurantsSection.displayName = 'SuggestedRestaurantsSection'

export default SuggestedRestaurantsSection
