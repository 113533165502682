import { styled } from '@zenchef/styled-system/jsx'
import { ComponentProps } from '@zenchef/styled-system/types'

import getTestId from '@/utils/getTestId'

const StyledSelectableButton = styled('button', {
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    height: 'xl',
    px: 'padding.3',
    gap: 'gap.2',
    border: 'm',
    borderColor: 'border.neutral-on-brand.bold',
    borderRadius: 'l',
    color: 'content.neutral.bold',
    textStyle: 'paragraph.m.regular',
    cursor: 'pointer',
    _hover: {
      bg: 'background.neutral-on-brand.base.subtlest-hover',
      borderColor: 'border.neutral-on-brand.bold-hover'
    },
    _focusVisible: {
      bg: 'background.brand.bold',
      borderColor: 'border.neutral-on-brand.bolder',
      shadow: 'effect.focus-ring.neutral',
      outline: 'none'
    },
    _disabled: {
      border: 'none',
      color: 'content.neutral.disabled',
      cursor: 'not-allowed'
    },
    _selected: {
      textStyle: 'paragraph.m.bold',
      bg: {
        base: 'background.neutral-on-brand.base.bold',
        _hover: 'background.neutral-on-brand.base.bold-hover',
        _focusVisible: 'background.neutral-on-brand.base.bold'
      },
      color: 'content.brand.bold',
      _focusVisible: {
        borderColor: 'border.brand.brand-brand'
      }
    },
    _aromaticonChildren: {
      fontSize: '20px'
    }
  },
  variants: {
    squared: {
      true: {
        height: '[56px]',
        width: '[56px]',
        justifyContent: 'center',
        textStyle: 'paragraph.m.bold'
      }
    }
  }
})

interface SelectableButtonProps extends ComponentProps<typeof StyledSelectableButton>, TestingAttributes {
  selected?: boolean
}

const SelectableItem = ({ testId, selected, ...props }: SelectableButtonProps) => {
  return (
    <StyledSelectableButton data-testid={getTestId(testId, 'btn')} data-selected={selected || undefined} {...props} />
  )
}

export default SelectableItem
